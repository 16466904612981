import { useLoading } from '@/composables/useLoading'
import { useCorporativeAccount } from '@/store/corporative'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRouter, RouteLocationNormalized } from 'vue-router'

export const globalPermissionsGuard = () => {
  const router = useRouter()
  const isAllowed = ref(true)

  const corporativeAccountStore = useCorporativeAccount()
  const { getCorporate, getCorporates } = corporativeAccountStore
  const { corporate, corporates, accountPermissions, isAccountOwner } =
    storeToRefs(corporativeAccountStore)

  const checkPermissions = async (to: RouteLocationNormalized) => {
    await useLoading(
      async () => {
        if (!corporates.value) await getCorporates()

        if (to.params.id) {
          if (!corporate.value?.id) await getCorporate(String(to.params.id))

          if (
            !isAccountOwner.value &&
            ((to.meta.isAdmin && !isAccountOwner.value) ||
              (typeof to.meta.requiredPermission === 'string' &&
                !accountPermissions.value.includes(
                  to.meta.requiredPermission
                )) ||
              (Array.isArray(to.meta.requiredPermission) &&
                !to.meta.requiredPermission.some((p: string) =>
                  accountPermissions.value.includes(p)
                )))
          ) {
            router.push({ name: 'home-event' })
            isAllowed.value = false
          } else {
            isAllowed.value = true
          }
        }
      },
      { global: true }
    )
  }

  router.beforeEach(async (to: RouteLocationNormalized, _from, next) => {
    await checkPermissions(to)
    if (isAllowed.value) {
      next()
    } else {
      next(false)
    }
  })

  return {
    isAllowed,
  }
}
