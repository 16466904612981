import { computed } from 'vue'
import { ref } from 'vue'

export const useMenuPosition = () => {
  const triggerRect = ref<DOMRect>()
  function toggleMenu(event: Event) {
    triggerRect.value = triggerRect.value
      ? undefined
      : (event.target as HTMLDivElement).getBoundingClientRect()
  }

  const isOnRightSide = computed(
    () => Number(triggerRect.value?.x || 0) / document.body.clientWidth > 0.5
  )

  const isOnBottom = computed(
    () => Number(triggerRect.value?.y || 0) / document.body.clientHeight > 0.5
  )

  const computedStyles = computed(() =>
    triggerRect.value
      ? {
          bottom: isOnBottom.value
            ? `${document.body.clientHeight - triggerRect.value.top}px`
            : '',
          top: isOnBottom.value ? '' : `${triggerRect.value.bottom}px`,
          left: isOnRightSide.value ? '' : `${triggerRect.value.left}px`,
          right: isOnRightSide.value
            ? `${document.body.clientWidth - triggerRect.value.right}px`
            : '',
        }
      : {}
  )

  return {
    triggerRect,
    toggleMenu,
    computedStyles,
  }
}
