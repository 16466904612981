<script setup lang="ts">
import { RuleExpression, useField, useIsFormTouched } from 'vee-validate'
import { MaybeRef, computed, toValue } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    name: string
    type?: string
    rules?: MaybeRef<RuleExpression<unknown>>
    label?: string
    items?: Array<unknown>
    labelField?: string
    valueField?: string
    disabled?: boolean
    checked?: boolean
    defaultValue?: string
    loading?: boolean
    icon?: string
    leftPosition?: boolean
    onClickButton?: () => void
    decimalPlaces?: number
    hideErrorMessage?: boolean
    error?: string
    placeholder?: string
    emptyState?: boolean
  }>(),
  {
    type: 'text',
    labelField: 'name',
    valueField: 'value',
    items: undefined,
    rules: '',
    label: '',
    select: false,
    checked: false,
    defaultValue: undefined,
    icon: '',
    leftPosition: false,
    onClickButton: undefined,
    decimalPlaces: undefined,
    hideErrorMessage: false,
    error: undefined,
    placeholder: undefined,
    emptyState: true,
  }
)
const isFormTouched = useIsFormTouched()
const { value, errorMessage, setValue, meta, setTouched, handleBlur } =
  useField(
    () => props.name,
    props.type === 'checkbox' ? undefined : props.rules,
    {
      initialValue: props.defaultValue || undefined,
      validateOnMount: false,
    }
  )

const label = computed(
  () => `${props.label}${props.label && (meta.required ? ' *' : '')}`
)

const emit = defineEmits(['change', 'country-code', 'validate', 'blur'])

function handleChange(newValue: string | number | boolean | Event) {
  setTouched(true)

  if (newValue instanceof Event) {
    const target = newValue.target as HTMLInputElement

    if (target.type === 'checkbox') {
      newValue = target.checked
    } else {
      newValue = target.value
    }
  }
  emit('change', newValue)
  setValue(newValue)
}
const error = computed(() => {
  if (props.error) return props.error
  return isFormTouched.value ? errorMessage : undefined
})

const { type } = props
</script>
<template>
  <div id="form-input" class="flex w-full flex-col" :class="$attrs.class">
    <Select
      v-if="type === 'select'"
      v-bind="{ ...$attrs }"
      v-model="value"
      :empty-state="props.emptyState"
      :name="name"
      :disabled="loading || disabled"
      :checked="checked"
      :label="label"
      :placeholder="placeholder"
      :items="props.items"
      :error="errorMessage ? true : false"
      :label-field="props.labelField"
      :value-field="props.valueField"
      @update:model-value="handleChange"
      @input="handleChange"
    />

    <PhoneInput
      v-else-if="type === 'phone'"
      v-bind="{ ...$attrs }"
      v-model="value"
      :name="name"
      :type="type"
      :label="label"
      :disabled="loading || disabled"
      :checked="checked"
      :error="errorMessage ? true : false"
      @input="
        (value: string) => {
          setValue(value)
          $emit('change', value)
        }
      "
      @country-code="(value: string) => $emit('country-code', value)"
    />
    <Input
      v-else
      v-bind="{ ...$attrs }"
      v-model="value"
      :multiline="props.type === 'textarea'"
      :name="name"
      :disabled="loading || disabled"
      :checked="checked"
      :type="type"
      :label="label"
      :error="toValue(error)"
      :icon="icon"
      :left-position="leftPosition"
      :on-click-button="onClickButton"
      :decimal-places="props.decimalPlaces"
      :placeholder="placeholder"
      @input="handleChange"
      @blur="
        (e: Event) => {
          handleBlur(e, true)
          $emit('blur')
        }
      "
    />
    <div
      class="flex w-full justify-between"
      :class="{ 'opacity-30': disabled || loading }"
    >
      <div class="grow">
        <div
          v-if="
            isFormTouched &&
            type != 'phone' &&
            errorMessage &&
            !hideErrorMessage
          "
          id="error-message"
          class="mb-1 h-[1rem] pl-1 text-xs text-error"
        >
          {{ $t(errorMessage || '') }}
        </div>
      </div>
      <div v-if="$attrs.maxlength" class="mr-2 self-end text-xs">
        {{ typeof value === 'string' ? value?.length : 0 }}/<span
          class="text-green"
          >{{ $attrs.maxlength }}</span
        >
      </div>
    </div>
  </div>
</template>
