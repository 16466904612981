import { createI18n } from 'vue-i18n'
import pt from '@/locales/pt.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'

export type MessageSchema = typeof pt

export type Locales = 'en' | 'PT-BR' | 'es' | 'PT'

export const i18n = createI18n<[MessageSchema], Locales>({
  locale: 'PT-BR',
  fallbackLocale: 'PT-BR',
  messages: {
    'PT-BR': pt,
    PT: pt,
    en: en,
    es: es,
  },
  missingWarn: false,
  globalInjection: true,
  legacy: false,
})
