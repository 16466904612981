<template>
  <div class="h-full w-full overflow-hidden bg-grey-7">
    <div class="flex h-[100vh] flex-col overflow-auto text-center">
      <img
        src="../../assets/images/bg-no-events.png"
        alt=""
        class="w-[60%] self-center"
      />
      <h1 class="mt-16 text-2xl font-bold text-grey-2">
        Você ainda não possui nenhum evento
      </h1>
      <Button class="mt-4 w-[18rem] self-center py-2">
        Criar novo evento
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
