<script setup lang="ts">
import menuDotsIcon from '@/assets/icons/menu_dots.svg'

import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { computed, ref } from 'vue'

defineProps<{ disabled?: boolean }>()
const triggerRect = ref<DOMRect>()
function toggleMenu(event: Event) {
  triggerRect.value = (event.target as HTMLDivElement).getBoundingClientRect()
}

const isOnRightSide = computed(
  () => Number(triggerRect.value?.x || 0) / document.body.clientWidth > 0.5
)

const isOnBottom = computed(
  () => Number(triggerRect.value?.y || 0) / document.body.clientHeight > 0.5
)

const computedStyles = computed(() =>
  triggerRect.value
    ? {
        bottom: isOnBottom.value
          ? `${document.body.clientHeight - triggerRect.value.top}px`
          : '',
        top: isOnBottom.value ? '' : `${triggerRect.value.bottom}px`,
        left: isOnRightSide.value ? '' : `${triggerRect.value.left}px`,
        right: isOnRightSide.value
          ? `${document.body.clientWidth - triggerRect.value.right}px`
          : '',
      }
    : {}
)
</script>

<template>
  <Menu v-slot="{ close }" as="div" class="relative">
    <MenuButton
      id="menu-button"
      class="menu-button"
      as="button"
      :disabled="disabled || false"
      @click="toggleMenu"
    >
      <img
        :src="menuDotsIcon"
        alt=""
        class="rotate-90 cursor-pointer"
        :class="{ 'opacity-30': disabled, 'cursor-not-allowed': disabled }"
      />
    </MenuButton>

    <Teleport to="body">
      <MenuItems
        as="div"
        class="absolute z-50 mt-2 w-max origin-top-left divide-y rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        :style="computedStyles"
      >
        <Card
          class="flex h-fit w-full !items-start !justify-start !text-left"
          color="green"
        >
          <div class="flex w-full flex-col">
            <slot name="menu-slot"></slot>
            <slot :close="close" />
          </div>
        </Card>
      </MenuItems>
    </Teleport>
  </Menu>
</template>
