<script lang="ts" setup>
import closeIcon from '@/assets/icons/black/close.svg'
import {
  DialogPanel,
  Dialog as HuiDialog,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  open?: boolean
  id?: string
  title?: string
  text?: string
  modelValue?: boolean
  loading?: boolean
  maxWidth?: string
}>()
const { text, title, id } = toRefs(props)
const emit = defineEmits(['close', 'update:modelValue'])

const isOpen = computed({
  get() {
    return props.open || props.modelValue
  },
  set(v: boolean) {
    emit('close')
    emit('update:modelValue', v)
  },
})
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <div class="relative">
      <HuiDialog
        as="div"
        class="fixed inset-0 z-50 flex items-center justify-center"
      >
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center bg-black/50 p-4 text-center"
            @click="isOpen = false"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <LoadingIcon v-if="props.loading" class="w-max rounded-full" />
              <DialogPanel
                v-else
                :id="id"
                :class="`flex transform flex-col gap-2 rounded-xl bg-grey-7 text-left align-middle shadow-xl transition-all ${maxWidth ?? 'w-[32rem]'}`"
              >
                <div class="flex flex-col items-center gap-2 px-6 py-4">
                  <div v-if="title" class="my-2 flex w-full justify-between">
                    <h3
                      class="dash w-[92%] truncate text-2xl font-bold text-grey-9"
                    >
                      <span v-if="title">{{ title }}</span>
                      <slot name="title" />
                    </h3>
                    <button
                      id="close-dialog"
                      type="button"
                      @click="isOpen = false"
                    >
                      <img :src="closeIcon" alt="close icon" />
                    </button>
                  </div>

                  <slot @close.stop></slot>
                  <div v-if="text">
                    {{ text }}
                  </div>
                </div>
                <div v-if="$slots.actions" class="my-2 flex justify-center">
                  <slot v-if="!props.loading" name="actions"></slot>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </HuiDialog>
    </div>
  </TransitionRoot>
</template>
